import {Component, OnDestroy} from '@angular/core';
import {NavItemComponent} from "../nav-item/nav-item.component";
import {AppRoute} from "../../../model/routes.model";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {RoutingService} from "../../../services/routing.service";
import {Title} from "@angular/platform-browser";
import {RouteService} from "../../../services/route.service";


@Component({
  selector: 'cep-nav-menu',
  standalone: true,
  imports: [
    NavItemComponent
],
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss'
})
export class NavMenuComponent implements OnDestroy {
  routes: AppRoute[] | undefined;
  currentRoute!: string;

  routeSubscription!: Subscription;

  constructor(
    private routeService: RouteService,
    private router: Router,
    private routingService: RoutingService,
    private titleService: Title
  ) {
    this.routes = this.routeService.routesData;

    this.routeSubscription = this.routingService.currentRoute.subscribe(
      (route: string) => {
        this.currentRoute = route;
      }
    );
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  goTo(item: AppRoute): void {
    this.titleService.setTitle('CIH :: ' + item.label);
    this.router.navigate(['/' + item.route]);
  }
}
