<div class="page-content">
  <div class="logo">
    <img src="assets/images/round-logo-200x200.svg" alt="Cyber Intelligence House"/>
  </div>
  <div class="info">
    <h1>CIH Management App</h1>
    <h2>This is not the page you are looking for...</h2>
    <p>
      You don't have permission to access this page. If you feel you should have access, please get in touch.
    </p>
    <button class="full primary" (click)="logout()">Logout</button>
  </div>
</div>
