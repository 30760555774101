export interface AppRoute {
  label: string;
  icon: string;
  route: DefaultRoute | string;
  showNew?: boolean;
}

export const DefaultRoutes = {
  ACCOUNTS: 'accounts',
  USERS: 'users',
  LIVE: 'live',
  RISKS: 'risks',
  CEI: 'cei'
} as const;



export type DefaultRoute =
  | typeof DefaultRoutes.LIVE
  | typeof DefaultRoutes.ACCOUNTS
  | typeof DefaultRoutes.USERS
  | typeof DefaultRoutes.CEI
  | typeof DefaultRoutes.RISKS;


export const RouteLabels = {
  ACCOUNTS: 'MSSPs Accounts',
  USERS: 'Users',
  LIVE: 'Live',
  RISKS: 'Risk Management',
  CEI: 'Cyber Exposure Index',
} as const;

export const RouteIcons = {
  ACCOUNTS: 'manage_accounts',
  USERS: 'people',
  LIVE: 'intelligence.svg',
  RISKS: 'security',
  CEI: 'analytics|outlined',
} as const;
