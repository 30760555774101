import {Component} from '@angular/core';

import {RouterOutlet} from '@angular/router';
import {HeaderBarComponent} from "./components/interface/header-bar/header-bar.component";

@Component({
  selector: 'cep-root',
  standalone: true,
  imports: [RouterOutlet, HeaderBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'CIH :: Management';
}
