import {Component, Input} from '@angular/core';

import {IconComponent} from "../../ui/icon/icon.component";

@Component({
  selector: 'cep-nav-item',
  standalone: true,
  imports: [
    IconComponent
],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss'
})
export class NavItemComponent {
 @Input()
  icon!: string;

  @Input()
  label!: string;

  @Input()
  showNew = false;

  @Input()
  selected = false;
}
