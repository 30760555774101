import { Component } from '@angular/core';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'cep-no-access',
  standalone: true,
  imports: [],
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.scss'
})
export class NoAccessComponent {

  constructor(private authService: AuthService) {
  }

  logout(): void {
    this.authService.logout();
  }
}
