import {Component, Input} from '@angular/core';

@Component({
  selector: 'cep-user-avatar',
  standalone: true,
  imports: [],
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss'
})
export class UserAvatarComponent {
  @Input()
  name!: string;
}
