import {CanMatchFn, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";

export function matchRoleGuard(roles:string | string[]): CanMatchFn {
  return () => {

    const authService = inject(AuthService);
    const router = inject(Router);

    if (!authService.hasRole(roles)) {
      return router.createUrlTree(['no-access']);
    } else {
      return true;
    }
  }
}
