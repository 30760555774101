import {Component, inject} from '@angular/core';
import {NavMenuComponent} from "../nav-menu/nav-menu.component";
import {UserGreetingComponent} from "../user-greeting/user-greeting.component";
import {Router} from "@angular/router";

@Component({
  selector: 'cep-header-bar',
  standalone: true,
  imports: [
    NavMenuComponent,
    UserGreetingComponent
  ],
  templateUrl: './header-bar.component.html',
  styleUrl: './header-bar.component.scss'
})
export class HeaderBarComponent {

  router = inject(Router);


   goHome() {
     this.router.navigate(['/']);
   /* this.router.navigate([
      this.appSettings.homeRoute ?? this.appSettings.startingRoute,
    ]);*/
  }
}
