import {Injectable} from '@angular/core';
import {Routes} from "@angular/router";
import {AuthService} from "./auth.service";
import {UserRoles} from "../model/user.model";
import {AppRoute, DefaultRoutes, RouteIcons, RouteLabels} from "../model/routes.model";
import {authGuard} from "../guards/auth.guard";
import {matchRoleGuard} from "../guards/match-role.guard";
import {NoAccessComponent} from "../pages/no-access/no-access.component";

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  _routes!: Routes;
  startingRoute = 'no-access';

  _routesData: AppRoute[] = [];



  constructor(
    private authService: AuthService) {

    this._routes = [
      {
        path: '',
        children: this.getAvailableRoutes(),
        canActivate: [authGuard],
      },
    ];
  }

  public get routes(): Routes {
    return this._routes;
  }


  public get routesData(): AppRoute[] {
    return this._routesData;
  }

  private getAvailableRoutes(): Routes {

    const routes: Routes = [{
      path: 'no-access',
      component: NoAccessComponent,
      canActivate: [authGuard],
    }];

    if (this.authService.hasRole([UserRoles.ACCOUNT_MANAGER, UserRoles.SUPER_ADMIN, UserRoles.MANAGER])) {
      this.startingRoute = DefaultRoutes.ACCOUNTS
      routes.push({
        path: DefaultRoutes.ACCOUNTS,
        //pathMatch: 'full',
        loadChildren: () => import('./../modules/accounts/accounts.routes').then(mod => mod.ACCOUNTS_ROUTES),
        data: {
          canEdit: this.authService.hasRole([UserRoles.ACCOUNT_MANAGER, UserRoles.SUPER_ADMIN])
        },
        canActivate: [authGuard],
        canMatch: [matchRoleGuard([UserRoles.ACCOUNT_MANAGER, UserRoles.SUPER_ADMIN, UserRoles.MANAGER])]

      });
      this._routesData.push({
        label: RouteLabels.ACCOUNTS,
        icon: RouteIcons.ACCOUNTS,
        route: DefaultRoutes.ACCOUNTS
      })
    }


    /***
     * CEI module
     **/
    if (this.authService.hasRole([UserRoles.CEI_MANAGER, UserRoles.SUPER_ADMIN])) {
      this.startingRoute = this.startingRoute === 'no-access' ? DefaultRoutes.CEI : this.startingRoute;
      routes.push({
        path: DefaultRoutes.CEI,
        //pathMatch: 'full',
        loadChildren: () => import('./../modules/cei/cei.routes').then(mod => mod.CEI_ROUTES),
        canActivate: [authGuard],
        canMatch: [matchRoleGuard([UserRoles.CEI_MANAGER, UserRoles.SUPER_ADMIN])],
        data: {
          canEdit: this.authService.hasRole([UserRoles.CEI_MANAGER, UserRoles.SUPER_ADMIN])
        },

      });
      this._routesData.push({
        label: RouteLabels.CEI,
        icon: RouteIcons.CEI,
        route: DefaultRoutes.CEI
      })
    }



    routes.push(
      {
        path: '',
        redirectTo: this.startingRoute,
        pathMatch: 'full',
      },
      {path: '**', redirectTo: this.startingRoute}
    );

    return routes;
  }
}
