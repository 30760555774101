@if (userName) {
  <div class="greeting">
    <div class="user-name">
      Hi, {{userName}}
    </div>
    <cep-user-avatar [matMenuTriggerFor]="userMenu" [name]="userName" class="user-menu-button"></cep-user-avatar>
    <mat-menu #userMenu="matMenu" xPosition="after" class="user-menu">
      <button mat-menu-item class="user-menu-item" [routerLink]="['/user/settings']">Settings</button>
      <button mat-menu-item class="user-menu-item" (click)="doLogout()">Logout</button>
    </mat-menu>
  </div>
}
