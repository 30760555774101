import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {MatMenuModule} from "@angular/material/menu";
import {UserAvatarComponent} from "../user-avatar/user-avatar.component";
import {RouterLink} from "@angular/router";


@Component({
  selector: 'cep-user-greeting',
  standalone: true,
  imports: [
    MatMenuModule,
    UserAvatarComponent,
    RouterLink
],
  templateUrl: './user-greeting.component.html',
  styleUrl: './user-greeting.component.scss'
})
export class UserGreetingComponent implements OnInit {

  userName = '';

  constructor(private userService: UserService, private authService: AuthService) {
  }

  ngOnInit() {


   this.userService.user.subscribe((value) => {this.userName =
        value.first_name && value.first_name !== ''
          ? value.first_name
          : value.username;
    });
  }

  doLogout(): void {
   this.authService.logout();
  }

}
