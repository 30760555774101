<nav>
  @if (routes) {
    @for (item of routes; track item) {
      <cep-nav-item
        [label]="item.label"
        [icon]="item.icon"
        [selected]="currentRoute.includes(item.route)"
        [showNew]="item.showNew ?? false"
        (click)="goTo(item)"
        [class]="'navItem'"
      ></cep-nav-item>
    }
  }
</nav>
