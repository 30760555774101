import {Injectable} from '@angular/core';
import {User, UserRole, UserRoles, UserTypeRoles} from "../model/user.model";
import {BehaviorSubject, map, Observable, share} from "rxjs";
import {environment} from "../../environments/environment";
import {objectToFormData} from "../utils/object";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser$!: Observable<User> | null;
  private currentUser = new BehaviorSubject<User>({} as User);
  private roles: UserRole[] = [];


  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  get user(): Observable<User> {
    if (!this.currentUser$) {
      this.currentUser$ = this.authService.getUserProfile().pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((profile: any) => {
          const user: User = {
            id: profile.id,
            username: profile.username,
            email: profile.email,
            first_name: profile.firstName,
            last_name: profile.lastName,
            name: profile.firstName + ' ' + profile.lastName,
            roles: this.authService.userRoles,
            settings: null,
            enabled: true,
          };

          this.currentUser.next(user);
          this.roles = this.authService.userRoles;

          return user;
        }),
        share()
      );

      //*/
    }

    return this.currentUser$;
  }


  clearUser(): void {
    this.currentUser.next({} as User);
    this.currentUser$ = null;
    this.roles = [];
  }


  updatePassword(data: {
    old_password: string;
    new_password: string;
  }): Observable<boolean> {
    const formData: FormData = new FormData();
    objectToFormData(formData, data);

    return this.httpClient
      .post(`${environment.BASE_API_URL}/${environment.API_SPACES.API}/auth/password/change`, formData, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          //update account observable
          return response.status === 204;
        })
      );
  }


  // Utility methods
  hasRole(role: UserRole): boolean {
    return this.roles.includes(role);
  }

  isUserAdmin(user: User): boolean {
    return user.roles.includes(UserRoles.ADMIN);
  }

  cleanUpRoles(roles: string[]): string[] {
    return roles.filter((role) => UserTypeRoles.includes(role));
  }
}
