import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, ROUTES} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakOptions, KeycloakService} from "keycloak-angular";
import {AuthService} from "./services/auth.service";
import {environment} from "../environments/environment";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {RouteService} from "./services/route.service";
import {provideHotToastConfig} from "@ngneat/hot-toast";

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
  const options: KeycloakOptions = {
    config: {
      url: environment.AUTH_SERVER_URL,
      realm: environment.AUTH_REALM,
      clientId: environment.AUTH_CLIENT_ID,
    },
    loadUserProfileAtStartUp: true,
    initOptions: {
      //enableLogging: true,
      //onLoad: 'check-sso',
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
    bearerExcludedUrls: [],
    shouldAddToken: (request) => {
      const addToken = request.headers.get('no-token') !== 'true';
      const isContentAPI = request.url.startsWith(environment.CONTENT_API_URL);
      return addToken && !isContentAPI;
    },
  };

  return () => keycloak.init(options);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter([]),
    {
      provide: ROUTES,
      useFactory: (routeService: RouteService) => routeService.routes,
      deps: [RouteService],
      multi: true,
    },
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    //importProvidersFrom(HttpClientModule),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    importProvidersFrom(KeycloakAngularModule),
    AuthService,
    KeycloakService,
    KeycloakBearerInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
      deps: [KeycloakBearerInterceptor]
    },
    provideHotToastConfig({
      dismissible: true,
      autoClose: true,
      position: 'top-center',
      className: 'notifications',
      success: {
        style: {
          backgroundColor: '#58B458',
          color: '#ffffff',
        },
        iconTheme: {
          primary: '#ffffff',
          secondary: '#58B458',
        },
      },
      error: {
        style: {
          backgroundColor: '#FF1203',
          color: '#ffffff',
        },
        iconTheme: {
          primary: '#ffffff',
          secondary: '#FF1203',
        },
      },
      closeStyle: {},
    }), // @ngneat/hot-toast providers
  ]
};
