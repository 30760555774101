<div class="item" [id]="'nav-menu_'+label" [class.selected]="selected">
  @if (icon && icon !=='') {
    <cep-icon [icon]="icon" [inheritColor]="true" class="icon"></cep-icon>
  }
  <div class="label">{{label}}</div>
  @if (showNew) {
    <div class="new-icon">
      <cep-icon [icon]="'new_releases|outlined'" [inheritColor]="true" [inline]="true"></cep-icon>
    </div>
  }
</div>
